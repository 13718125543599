import * as React from "react";
import { SVGProps } from "react";

const SVGCircleMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={80}
    height={80}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={40} cy={40} r={40} fill="#EBDD60" />
  </svg>
);

export default SVGCircleMedium;
