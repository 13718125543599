import React, {
  CSSProperties,
  MouseEventHandler,
  SyntheticEvent,
  useEffect,
  useState,
} from "react";
import "./textInput.scss";
import { Eye } from "tabler-icons-react";
type TextInputProps = {
  label: string;
  value?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
  icon?: any;
  iconOnClick?: () => any;
  onBlur?: () => any;
  style?: CSSProperties;
  autoFocus?: boolean;
  className?: string;
  onKeyPressEnter?: () => any;
  capFirstLetter?: boolean;
  maxLength?: number | undefined;
  type?: "string" | "number" | "phone" | "password";
  validationPopup?: any;
};
const TextInput = (props: TextInputProps) => {
  const {
    label = "",
    value = "",
    onChange,
    placeholder = "",
    disabled = false,
    iconOnClick = () => console.error("No Function Given To iconOnClick"),
    style,
    autoFocus = false,
    className = "",
    onKeyPressEnter = () => {},
    capFirstLetter = false,
    maxLength = undefined,
    type = "string",
    validationPopup,
    onBlur,
  } = props;
  const [internalValue, setInternalValue] = useState(value);
  const [displayValidation, setDisplayValidation] = useState(false);

  const handleKeyPressEnter = (
    event: SyntheticEvent<HTMLInputElement> & { keyCode: number }
  ) => {
    if (event.keyCode === 13) {
      onKeyPressEnter();
    }
  };
  useEffect(() => {
    if (onChange) {
      onChange(internalValue);
    }
  }, [internalValue]);
  const handleChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    const initialValue = event.target.value as string;
    let newValue = capFirstLetter
      ? `${initialValue.charAt(0).toUpperCase()}${initialValue.slice(1)}`
      : initialValue;
    if (type === "number") {
      newValue = newValue.replace(/[^\d]/g, "");
    }
    if (type === "phone") {
      const areaCode = `(${internalValue.substring(0, 3)})`;
      setInternalValue(areaCode + internalValue.slice(3));
    }
    setInternalValue(newValue);
  };
  return (
    <div
      className="text-input-parent"
      style={{
        ...style,
      }}
    >
      <label>{label}</label>
      {validationPopup && displayValidation && validationPopup}
      <input
        autoFocus={autoFocus}
        onFocus={() => {
          if (validationPopup !== undefined) {
            setDisplayValidation(true);
          }
        }}
        onBlur={() => {
          if (validationPopup !== undefined) {
            setDisplayValidation(false);
          }
        }}
        className={`lib-text-input ${className}`}
        value={internalValue}
        placeholder={placeholder}
        onChange={handleChange}
        disabled={disabled}
        style={style}
        onKeyUp={handleKeyPressEnter}
        maxLength={maxLength}
        type={type !== "password" ? "string" : "password"}
        onBlurCapture={onBlur}
      />
      {props.icon && (
        <div
          style={{
            position: "relative",
            right: 30,
            cursor: "pointer",
          }}
          onClick={iconOnClick}
        >
          {props.icon}
        </div>
      )}
    </div>
  );
};

export default TextInput;
