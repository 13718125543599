import * as React from "react";
import { SVGProps } from "react";
import { CustomIconProps } from "./customIcon";

const CustomIconActionNoCircle = (props: CustomIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 414.3 252.92"
    width={props.size || undefined}
    height={props.size || undefined}
    {...props}
  >
    <defs>
      <style>
        {
          ".cls-1{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:40.3px}"
        }
      </style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <circle className="cls-1" cx={287.84} cy={126.46} r={106.31} />
        <path
          className="cls-1"
          d="M125 126.46h-23.9M128.33 56.13H20.15M128.33 196.79H20.15"
        />
      </g>
    </g>
  </svg>
);

export default CustomIconActionNoCircle;
