import React, {
  CSSProperties,
  SyntheticEvent,
  useEffect,
  useState,
} from "react";
import "./dropdown.scss";
import { ChevronDown } from "tabler-icons-react";

type DropdownProps = {
  value?: string;
  options: { label: string; value: string }[];
  disabled?: boolean;
  onChange?: (value: string) => void;
  firstOptionIsDefault?: boolean;
  style?: CSSProperties;
};
const Dropdown = (props: DropdownProps) => {
  const {
    value = "",
    options,
    disabled = false,
    firstOptionIsDefault,
    style,
  } = props;
  const allOptions = props.options.map((option, index) => {
    return (
      <option key={index} value={option.value}>
        {option.label}
      </option>
    );
  });
  const [internalValue, setInternalValue] = useState(value);
  const handleSelect = (event: { target: { value: string } }) => {
    setInternalValue(event.target.value);
  };
  useEffect(() => {
    props.onChange && props.onChange(internalValue);
  }, [internalValue]);
  return (
    <div style={{ position: "relative" }}>
      <select
        disabled={disabled}
        onChange={handleSelect}
        value={internalValue}
        style={style}
      >
        {firstOptionIsDefault !== true && (
          <option key={"default"} value={""} disabled>
            Select One
          </option>
        )}
        {allOptions}
      </select>
      <ChevronDown
        style={{ position: "absolute", top: "25%", right: "10px" }}
      />
    </div>
  );
};

export default Dropdown;
