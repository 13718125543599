import { Routes, Route } from "react-router-dom";
import "./App.scss";
import {
  DownloadPage,
  EmailPage,
  WelcomePage,
  AboutPage,
  HowItWorksPage,
  FriendsFamilyPage,
} from "./pages";
import AssessmentStepPage from "./pages/assessmentStepPage";
import "./i18n";
import { Suspense } from "react";
import { Layout } from "./components";
import Modal from "react-modal";
import { PrivacyPolicyPage, TermsOfUsePage } from "./pages/legal";
import DemoPage from "./pages/demoPage/demoPage";
import StagingIndicator from "./components/stagingIndicator/stagingIndicator";
import ScrollToTop from "./components/scrollToTop";
import PasswordResetPage from "./pages/passwordReset/passwordResetPage";
import { NotFound } from "./routes";
import NotaReimagined from "./pages/notaReimaginedPage/notaReimaginedPage";

Modal.setAppElement("#root");

function AppWithContext() {
  return (
    <Suspense fallback={<div />}>
      <Routes>
        <Route path="assessment" element={<AssessmentStepPage />} />
        <Route path="download" element={<DownloadPage />} />
        <Route path="email" element={<EmailPage />} />
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<WelcomePage />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="how-it-works" element={<HowItWorksPage />} />
          <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="terms-of-use" element={<TermsOfUsePage />} />
        </Route>
        <Route path="password-reset" element={<PasswordResetPage />} />
        <Route path="/component-demo" element={<DemoPage />} />
        <Route path="/nota-reimagined" element={<NotaReimagined />} />
        <Route
          path="friendsfamily/:firstName/:lastName/:userInteractionId/:hash"
          element={<FriendsFamilyPage />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ScrollToTop />
      <StagingIndicator />
    </Suspense>
  );
}

function App() {
  return <AppWithContext />;
}

export default App;
