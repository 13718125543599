import "./howItWorksPage.scss";
import "../aboutPage/aboutPage.scss";

const HowItWorksPage = () => {
  return (
    <div className="how-it-works-page about-page">
      <div className="how-it-works-page-content about-page-content">
        <h1>How It Works</h1>
        <p>
          Nota is made up of Journeys that focus on specific topics, each
          Journey contains a few Steps to unpack the main concept in a few
          minutes each. Each Journey ends with a challenge to help you apply
          what you’ve learned to your life to see real change over time.
        </p>
        <p>
          When you first create an account with Nota, you’ll complete an Initial
          Assessment to guage how you feel about your Identity and Purpose, and
          how well you put your Identity and Purpose into Action.
        </p>
        <p>
          Throughout your Journey through Nota, you’ll take this short
          Assessment frequently to track your growth over time.
        </p>
        <p>
          In Nota, we’ll give you the tools to answer questions like: “Who am I?
          Why am I here?” and “What makes me unique?”
        </p>
      </div>
    </div>
  );
};

export default HowItWorksPage;
