import * as React from "react";
import { SVGProps } from "react";
import { CustomIconProps } from "./customIcon";

const CustomIconIAmNoCircle = (props: CustomIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 337.6 328.3"
    width={props.size || undefined}
    height={props.size || undefined}
    {...props}
  >
    <g data-name="Layer 2">
      <g data-name="Layer 1">
        <path
          style={{
            stroke: "#000",
            strokeLinejoin: "round",
            fill: "none",
            strokeLinecap: "round",
            strokeWidth: "40.3px",
          }}
          d="M246.15 194.15v114h-226v-288h226"
        />
        <path
          style={{
            strokeWidth: "15.22px",
            stroke: "#000",
            strokeLinejoin: "round",
          }}
          d="m189.13 191.84-50.98 18.31 19.81-49.48L298.81 19.82l31.18 31.17-140.86 140.85z"
        />
      </g>
    </g>
  </svg>
);

export default CustomIconIAmNoCircle;
