import React, { useEffect } from "react";
import "./halfCircleProgressBar.scss";
import { colors } from "../constants";

const HalfCircleProgressBar = (props: { value: number; title: string }) => {
  useEffect(() => {
    const meters = document.querySelectorAll("svg[data-value] .meter");

    meters.forEach((path: any) => {
      // Get the length of the path
      let length = path.getTotalLength();
      // console.log(length) once and hardcode the stroke-dashoffset and stroke-dasharray in the SVG if possible
      // or uncomment to set it dynamically
      // path.style.strokeDashoffset = length;
      // path.style.strokeDasharray = length;

      // Get the value of the meter
      let value = parseInt(path.parentNode.getAttribute("data-value"));
      // Calculate the percentage of the total length
      let to = length * ((100 - value) / 100);
      // Trigger Layout in Safari hack https://jakearchibald.com/2013/animated-line-drawing-svg/
      path.getBoundingClientRect();
      // Set the Offset
      path.style.strokeDashoffset = Math.max(0, to);
    });
  });
  let mainColor;
  let altColor;
  let textColor;
  if (props.title === "Identity") {
    textColor = colors.primary[4];
    mainColor = colors.primary[4];
    altColor = colors.primary[1];
  }
  if (props.title === "Purpose") {
    mainColor = colors.warning[4];
    textColor = colors.warning[5];
    altColor = colors.warning[1];
  }
  if (props.title === "Action") {
    mainColor = colors.success[4];
    textColor = colors.success[4];
    altColor = colors.success[1];
  }
  return (
    <div className={"progress-wrapper"}>
      <h3 className={"progress-title"} style={{ color: textColor }}>
        {props.title}
      </h3>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="100%"
        width="100%"
        viewBox="0 0 200 200"
        data-value={`${props.value}`}
        id={"halfCircleProgressBar"}
      >
        <path
          className="bg"
          stroke={altColor}
          d="M41 149.5a77 77 0 1 1 117.93 0"
          fill="none"
        />
        <path
          className="meter"
          stroke={mainColor}
          d="M41 149.5a77 77 0 1 1 117.93 0"
          fill="none"
          strokeDasharray="350"
          strokeDashoffset="350"
        />
      </svg>
      <h1 className={"value-percent"} style={{ color: textColor }}>
        {props.value}%
      </h1>
    </div>
  );
};

export default HalfCircleProgressBar;
