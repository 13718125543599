import * as React from "react";
import { SVGProps } from "react";
import { CustomIconProps } from "./customIcon";

const CustomIconPrayerWithCircle = (props: CustomIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 572.3 572.3"
    width={props.size || undefined}
    height={props.size || undefined}
    {...props}
  >
    <defs>
      <style>
        {
          ".cls-1{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:40.3px}"
        }
      </style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <circle className="cls-1" cx={286.15} cy={286.15} r={266} />
        <path
          className="cls-1"
          d="m286.15 467.15-17.99-86.18M304.14 191.33l-17.99-86.18M105.15 286.15l86.18-17.99M380.97 304.14l86.18-17.99M158.17 414.14l48.21-73.66M365.92 231.82l48.22-73.65M158.17 158.17l73.65 48.21M340.48 365.92l73.66 48.22"
        />
      </g>
    </g>
  </svg>
);

export default CustomIconPrayerWithCircle;
