import { VideoPlayer } from "../../../components";
import { MediaResource, VideoData } from "../../../types/resource.types";
import parse from "html-react-parser";
import { Button, Card } from "../../../components/library";
import React, { useState } from "react";

interface MediaStepProps {
  resource: MediaResource;
  controls: any;
}

const getVideoUrl = (video: VideoData) => {
  if (video) {
    return video["1080p"];
  }

  return null;
};
const MediaStep = ({ resource, controls }: MediaStepProps) => {
  const [currentTime, setCurrentTime] = useState(0);

  return (
    <Card>
      <div className="card-title-section">
        <h1>{resource.title}</h1>
        <p>{parse(resource.description)}</p>
      </div>
      <div className="media-wrapper">
        <VideoPlayer
          onTimeChange={(time) => {
            setCurrentTime(time);
          }}
          source={getVideoUrl(resource.data.video)}
        />
      </div>
      <Button
        text={
          currentTime < 5 && resource.data.video !== undefined
            ? `Continue (${5 - currentTime})`
            : "Continue"
        }
        onClick={controls.goForward}
        disabled={
          (!controls.canGoForward() || currentTime < 5) &&
          resource.data.video !== undefined
        }
      />
    </Card>
  );
};

export default MediaStep;
