import { useNavigate } from "react-router-dom";
import { VideoPlayer } from "../../components";
import {
  Button,
  Spacer,
  PhoneAndBlob,
  SwooshArrow,
} from "../../components/library";
import AppStoreLink from "../../components/appStoreLink/appStoreLink";
import "./welcomePage.scss";
import blobAppPreview from "../../assets/blobAppPreview.svg";
import { useEffect, useState } from "react";

interface WelcomePageProps {}

const WelcomePage = ({}: WelcomePageProps) => {
  const navigate = useNavigate();
  const [OS, setOS] = useState("other");
  const getOS = () => {
    const isIphone = navigator.userAgent.indexOf("iPhone") != -1;
    const isIpod = navigator.userAgent.indexOf("iPod") != -1;
    const isIpad = navigator.userAgent.indexOf("iPad") != -1;
    const isAndroid = navigator.userAgent.indexOf("Android") != -1;
    if (isIphone || isIpod || isIpad) {
      setOS("ios");
    } else if (isAndroid) {
      setOS("android");
    }
  };
  const hasStartedAssessment = !!localStorage.getItem(
    "nota:assessmentStepIndex"
  );

  useEffect(() => {
    getOS();
  }, []);

  const resetAssessment = () => {
    localStorage.removeItem("nota:assessmentStepIndex");
    localStorage.removeItem("nota:answers");
    navigate("assessment");
  };

  return (
    <div className="welcome-page">
      <div className="welcome-info">
        <p className="subhead">Welcome to Nota</p>
        <h1 className={""}>We want you to experience life to the fullest</h1>
        <div className="cta-buttons">
          {/*<Button
            style={{ width: "200px" }}
            text={"Get Started"}
            onClick={resetAssessment}
          />*/}
          {/* <Button
            type={"text"}
            style={{ width: "200px" }}
            text={"Watch Video"}
            icon={<PlayerPlay fill={"#08516F"} stroke={"#08516F"} />}
            onClick={() => {}}
          /> */}
        </div>
        <div className={"spacers"}>
          <div className={"mobile"}>
            <Spacer size={5} />
          </div>
          <div className={"desktop"}>
            <Spacer size={8} />
            <Spacer size={8} />
          </div>
        </div>
        <div className={"download-section"}>
          <SwooshArrow className={"swoosh"} />
          <h6>DOWNLOAD NOW</h6>
          <Spacer size={3} />
          <div className="store-links">
            <AppStoreLink type="ios" />
            <AppStoreLink type="android" />
          </div>
        </div>
      </div>

      <img className="phone-mockup" src={blobAppPreview} />
    </div>
  );
};

export default WelcomePage;
