import * as React from "react";
import { SVGProps } from "react";
import { CustomIconProps } from "./customIcon";

const CustomIconIAmCircle = (props: CustomIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 572.3 572.3"
    width={props.size || undefined}
    height={props.size || undefined}
    {...props}
  >
    <defs>
      <style>
        {
          ".cls-1{fill:none;stroke-linecap:round;stroke-width:40.3px;stroke:#000;stroke-linejoin:round}"
        }
      </style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <circle className="cls-1" cx={286.15} cy={286.15} r={266} />
        <path className="cls-1" d="M374.15 315.15v114h-226v-288h226" />
        <path
          style={{
            strokeWidth: "15.22px",
            stroke: "#000",
            strokeLinejoin: "round",
          }}
          d="m317.13 312.85-50.98 18.3 19.81-49.48 140.85-140.86 31.18 31.18-140.86 140.86z"
        />
      </g>
    </g>
  </svg>
);

export default CustomIconIAmCircle;
