import * as React from "react";
import { SVGProps } from "react";

const SwooshArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={41}
    height={89}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.028 87.788c.831.07 2.588.601 3.168-.187.798-1.092-.512-1.586-1.316-1.975-1.613-.712-4.445-.69-5.702-1.824 12.38-5.79 22.13-15.53 27.743-28.123 5.607-12.528 7.81-28.234 1.535-40.976C33.372 8.465 28.669 3.12 21.987.7c-.564-.175-3.36-1.372-3.332-.148.01.644 3.62 2.102 4.169 2.47 3.125 1.867 5.697 4.203 7.902 7.087 7.542 9.89 8.068 23.698 4.926 35.21-2.111 7.607-5.936 14.944-10.954 21.153-2.581 3.195-5.53 6.167-8.824 8.66-2.746 2.087-7.217 5.576-10.789 5.987.702-3.03 4.38-5.49 5.587-8.413.644-1.555-.038-3.413-1.917-2.475-1.544.772-2.677 3.573-3.565 4.979a71.99 71.99 0 0 0-2.842 4.781c-.617 1.236-2.48 3.525-2.08 4.91.827 2.448 8.658 2.647 10.76 2.886.895.075-.889-.138 0 0Z"
      fill="#93C7D4"
    />
  </svg>
);

export default SwooshArrow;
