import { useState } from "react";
import { Button, Card, SvgPhones, colors } from "../../components/library";
import "./downloadPage.scss";
import "react-phone-number-input/style.css";
import { MulticolorListIcon } from "../../components/library/icons";
import { sendAppLink } from "../../api/apiClient";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import appPreview from "../../assets/appPreview.png";

const DownloadPage = (props: { titleOverride?: string }) => {
  const [phone, setPhone] = useState("");
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const handleChange = (text: string) => {
    setPhone(text);
  };
  const sendPhoneNumber = async () => {
    const result = await sendAppLink(phone);
    setHasSubmitted(true);
  };

  return (
    <div className="download-page">
      {!hasSubmitted ? (
        <Card>
          <div className={"content-wrapper initial-card"}>
            <h1 className={"title"}>
              {props.titleOverride ||
                "To start your journey, download the Nota app"}
            </h1>
            <p>
              Enter your phone number and we’ll send you a text with the link to
              the app.
            </p>
            <div className="phone-wrapper">
              {/* <TextInput
                value={phone}
                onChange={handleChange}
                placeholder="Phone number"
                autoFocus
                type={"number"}
              /> */}
              <PhoneInput
                className="phone-input"
                value={phone}
                onChange={handleChange}
                focusInputOnCountrySelection
                defaultCountry="US"
                name="phone"
                placeholder="Phone number"
                autoFocus
              />

              <Button
                text="Send Text"
                onClick={sendPhoneNumber}
                disabled={!isValidPhoneNumber(phone)}
                style={{ width: "200px" }}
              />
            </div>
            <p className="small">
              Message and date rates may apply. 4 msgs/month Text HELP to 763-64
              for hello. Text STOP to 763-64 to cancel.
            </p>
          </div>
        </Card>
      ) : (
        <Card>
          <div className={"content-wrapper"}>
            <MulticolorListIcon colors={[colors.accent[4], colors.accent[2]]} />
            <h1 className={"title"}>Thank you for signing up</h1>
            <p className="small">
              Check your phone for a text from us with instructions for
              downloading the app.
            </p>
            <Button
              text={"Didn't Get The Text?"}
              type={"text"}
              onClick={() => {
                setPhone("");
                setHasSubmitted(false);
              }}
            />
          </div>
        </Card>
      )}
      <img src={appPreview} className="app-preview" />
    </div>
  );
};

export default DownloadPage;
