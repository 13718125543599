import { CircleCheck, X } from "tabler-icons-react";
import {
  TextInput,
  Card,
  Button,
  Spacer,
  colors,
  Tooltip,
} from "../../../components/library";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useWebAssessment from "../../../hooks/useWebAssessment";
import { checkEmailExists } from "../../../api/apiClient";

interface CreateAccountStepProps {
  onChange: (name: string) => void;
  controls: any;
}

const CreateAccountStep = ({ onChange, controls }: CreateAccountStepProps) => {
  const [email, setEmail] = useState("");
  const [duplicateEmail, setDuplicateEmail] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [emailIsValid, setEmailIsValid] = useState(false);
  const [validData, setValidData] = useState({
    length: false,
    lowercase: false,
    uppercase: false,
    numbers: false,
    same: false,
    isValid: false,
  });
  const { onAssessmentSubmit } = useWebAssessment();

  useEffect(() => {
    const validEmail = validateEmail();
    setEmailIsValid(validEmail !== null);
    const passwordIsValidData = validatePassword();
    const passwordsAreTheSame = password === confirmPassword;
    setValidData(passwordIsValidData);
  }, [email, password, confirmPassword]);

  const handleSubmit = async () => {
    const duplicateEmail = await checkEmail();
    if (!duplicateEmail) {
      const result = await onAssessmentSubmit(email, password);
      if (!result?.error) {
        setSubmissionSuccess(true);
        controls.goForward();
      }
    }
  };

  const checkEmail = async () => {
    if (emailIsValid) {
      const result = await checkEmailExists(email);
      setDuplicateEmail(!!result.exists);
      return result.exists;
    }
    return false;
  };

  const onReset = () => {
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setSubmissionSuccess(false);
  };

  const validateEmail = () => {
    return email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
      );
  };

  const validatePassword = () => {
    const passwordData = {
      length: password.length > 8,
      lowercase: !!password.match(/[a-z]+/g),
      uppercase: !!password.match(/[A-Z]+/g),
      numbers: !!password.match(/[0-9]+/g),
      same: password === confirmPassword,
    };
    return {
      ...passwordData,
      isValid:
        passwordData.length &&
        passwordData.numbers &&
        passwordData.lowercase &&
        passwordData.uppercase &&
        passwordData.same,
    };
  };

  const PasswordValidationTooltipContent = () => {
    return (
      <div className={"password-validation-box"}>
        <div>
          {validData.length ? (
            <CircleCheck color={colors.success[4]} />
          ) : (
            <X color={colors.error[4]} />
          )}{" "}
          at least 8 characters
        </div>
        <div>
          {validData.uppercase ? (
            <CircleCheck color={colors.success[4]} />
          ) : (
            <X color={colors.error[4]} />
          )}{" "}
          at least 1 uppercase
        </div>
        <div>
          {validData.lowercase ? (
            <CircleCheck color={colors.success[4]} />
          ) : (
            <X color={colors.error[4]} />
          )}{" "}
          at least 1 lowercase
        </div>
        <div>
          {validData.numbers ? (
            <CircleCheck color={colors.success[4]} />
          ) : (
            <X color={colors.error[4]} />
          )}{" "}
          at least 1 number
        </div>
      </div>
    );
  };

  const ConfirmPasswordValidationTooltipContent = () => {
    return (
      <div className={"password-validation-box confirm-popup"}>
        <div>
          {validData.same && validData.isValid ? (
            <CircleCheck color={colors.success[4]} />
          ) : (
            <X color={colors.error[4]} />
          )}{" "}
          Passwords are the same
        </div>
      </div>
    );
  };

  return (
    <Card className={"name-card"}>
      <div className={"name-title-section"}>
        <h1>Join Nota</h1>
        <p>
          Create an account with your email address and password to receive a
          copy your results and access a link to join Nota.
        </p>
      </div>
      <div className={"text-input-wrapper"}>
        <div className={"name-input"}>
          <TextInput
            label="Email"
            placeholder="name@domain.com"
            value={email}
            onChange={setEmail}
            onBlur={checkEmail}
          />
          {duplicateEmail && (
            <p className="error-text">
              We're sorry! This email address already in use.
            </p>
          )}
        </div>
        <div className={"name-input"}>
          <p>Password</p>
          <Tooltip
            placement={"bottom"}
            title={"Password Must Be:"}
            actionArea={
              <TextInput
                label="Password"
                placeholder="********"
                value={password}
                onChange={setPassword}
                type={"password"}
              />
            }
          >
            <PasswordValidationTooltipContent />
          </Tooltip>

          <Spacer size={6} />
          <p>Confirm Password</p>
          <Tooltip
            placement={"bottom"}
            actionArea={
              <TextInput
                label="Confirm Password"
                placeholder="********"
                value={confirmPassword}
                onChange={setConfirmPassword}
                type={"password"}
              />
            }
          >
            <ConfirmPasswordValidationTooltipContent />
          </Tooltip>
        </div>
        <p className="disclaimer">
          Nota ensures the confidentiality of your personal information. We’ll
          email you a copy of your results for convenient access
        </p>
        <p className="disclaimer">
          By clicking “Continue” below you acknowledge that you have read,
          understood, and accepted the{" "}
          <a
            href="https://app.termly.io/document/eula/ff3e67f1-789f-4232-adeb-66bd4c3f5a58"
            target="_blank"
            className={"disclaimer-links"}
          >
            EULA
          </a>{" "}
          and{" "}
          <a
            href="https://app.termly.io/document/privacy-policy/a704575a-ea8c-42f2-8d85-4744ba4cac82"
            target="_blank"
            className={"disclaimer-links"}
          >
            Privacy Policy
          </a>{" "}
          . You may receive emails from Nota or our partners and can unsubscribe
          at any time.
        </p>
        <Button
          text="Continue"
          onClick={handleSubmit}
          disabled={duplicateEmail || !emailIsValid || !validData.isValid}
        />
      </div>
    </Card>
  );
};

export default CreateAccountStep;
