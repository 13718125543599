import * as React from "react";
import { SVGProps } from "react";
import { MulticolorProps } from "./multicolor";

const MulticolorMountainIcon = (props: MulticolorProps) => {
  const { colors = ["black"] } = props;
  return (
    <svg
      width={80}
      height={80}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M75.567 66.367 62.233 45.801a6.666 6.666 0 0 0-5.566-3.034 6.666 6.666 0 0 0-5.567 3.034l-4.467 6.666-13.3-20.066v-5.734h13.334a6.667 6.667 0 0 0 6.666-6.666v-6.667a6.667 6.667 0 0 0-6.666-6.667H33.333a3.333 3.333 0 0 0-6.666 0v25.6l-22.234 34.1a6.667 6.667 0 0 0-.3 6.667A6.667 6.667 0 0 0 10 76.667h60a6.667 6.667 0 0 0 5.867-3.333 6.667 6.667 0 0 0-.3-6.967Z"
        fill={colors[0]}
      />
    </svg>
  );
};

export default MulticolorMountainIcon;
