import * as React from "react";
import { SVGProps } from "react";
import { MulticolorProps } from "./multicolor";

const MulticolorBriefcaseIcon = (props: MulticolorProps) => {
  const { colors = ["black"] } = props;
  return (
    <svg
      width={80}
      height={80}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M76.667 36.667v-10a10 10 0 0 0-10-10h-3.334v-3.333a10 10 0 0 0-10-10H26.667a10 10 0 0 0-10 10v3.333h-3.334a10 10 0 0 0-10 10v10h73.334ZM23.333 13.334a3.333 3.333 0 0 1 3.334-3.333h26.666a3.333 3.333 0 0 1 3.334 3.333v3.333H23.333v-3.333Zm53.334 30v16.667A16.666 16.666 0 0 1 60 76.667H20A16.668 16.668 0 0 1 3.333 60.001V43.334h13.334v3.333A3.333 3.333 0 0 0 20 50.001h6.667A3.333 3.333 0 0 0 30 46.667v-3.333h20v3.333a3.333 3.333 0 0 0 3.333 3.334H60a3.333 3.333 0 0 0 3.333-3.334v-3.333h13.334Z"
        fill={colors[0]}
      />
    </svg>
  );
};

export default MulticolorBriefcaseIcon;
