import { faImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import {
  Button,
  TextInput,
  Card,
  SvgPhones,
  colors,
} from "../../components/library";
import "./email.scss";
import { LogoHorizontal } from "../../components/library/logos";
import { MulticolorListIcon } from "../../components/library/icons";

const EmailPage = () => {
  const [email, setEmail] = useState("");
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const handleChange = (text: string) => {
    setEmail(text);
  };
  const sendEmail = () => {
    // do sending here
    setHasSubmitted(true);
  };

  return (
    <div className="page email-page assessment-page">
      <LogoHorizontal width={150} color={colors.primary[4]} />
      {!hasSubmitted ? (
        <Card className={"card-email"}>
          <div className={"content-wrapper initial-card"}>
            <h1 className={"title"}>
              Enter your email address to receive a copy your results and access
              a link to join Nota.
            </h1>
            <p className={"small"}>
              Nota ensures the confidentiality of your personal information.
              We’ll email you a copy of your results for convenient access
            </p>
            <div className="email-wrapper">
              <TextInput
                label="Email"
                value={email}
                onChange={handleChange}
                placeholder="name@domain.com"
                autoFocus
                type={"string"}
              />
              <Button
                text="Send Email"
                onClick={sendEmail}
                disabled={email.length < 1}
              />
            </div>
            <p className="small">
              By clicking “Submit” above you acknowledge that you have read,
              understood, and accepted the EULA and Privacy Policy. You may
              receive emails from Nota or our partners and can unsubscribe at
              any time.
            </p>
          </div>
        </Card>
      ) : (
        <Card>
          <div className={"content-wrapper"}>
            <MulticolorListIcon colors={[colors.accent[4], colors.accent[2]]} />
            <h1 className={"title"}>
              Your email has been received. Please check your inbox to access
              your copy of your results.
            </h1>
            <Button
              text={"Didn't Get The Email?"}
              type={"text"}
              onClick={() => {
                setHasSubmitted(false);
              }}
            />
          </div>
        </Card>
      )}
    </div>
  );
};

export default EmailPage;
