import { Check } from "tabler-icons-react";
import "./sliderAnswers.scss";
import { colors } from "../library";

type SliderAnswersProps = {
  options: string[];
  value: number;
  onClick: (minValue: number) => void;
};

const SliderAnswers = ({ options, value, onClick }: SliderAnswersProps) => {
  const iteratorAmount = 100 / (options.length - 1);

  const getRange = (index: number) => {
    const lowEnd = Math.floor(index !== 0 ? index * iteratorAmount : 1);
    const highEnd = Math.floor(
      index === 0
        ? iteratorAmount - 1
        : index + 1 === options.length
        ? 100
        : (index + 1) * iteratorAmount - 1
    );
    return { lowEnd, highEnd };
  };

  return (
    <div className="slider-answers">
      {options.map((answer: string, index: number) => {
        const { lowEnd, highEnd } = getRange(index);
        //@ts-ignore
        const color = colors.primary[index + 1];
        const isSelected = value >= lowEnd && value <= highEnd;
        return (
          <div
            key={`answer${index}`}
            className={"answer-wrapper " + (isSelected && "selected")}
            onClick={() => onClick(lowEnd)}
          >
            <div
              className={"colored-circle"}
              style={{ backgroundColor: color }}
            />
            <div className="answer-content">
              <span className="answer-text">
                <p className={"small medium"}>{answer}</p>
              </span>
            </div>
            <div>{isSelected ? <Check /> : ""}</div>
          </div>
        );
      })}
    </div>
  );
};

export default SliderAnswers;
