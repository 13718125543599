//import { Tooltip } from "../../../components";
import {
  TextInput,
  Card,
  Button,
  SVGCircleMedium,
  SVGCircleLarge,
  SVGCircleSmall,
} from "../../../components/library";
import React, { useEffect, useState } from "react";

interface NameQuestionStepProps {
  value: string;
  onChange: (name: string) => void;
  controls: any;
}

const NameQuestionStep = ({
  value,
  onChange,
  controls,
}: NameQuestionStepProps) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  useEffect(() => {
    onChange(`${firstName} ${lastName}`);
  }, [firstName, lastName]);
  return (
    <Card className={"name-card"}>
      <div className={"card-title-section"}>
        <h1>Nice to meet you</h1>
        <p className="">What is your name?</p>
      </div>
      <div className={"text-input-wrapper"}>
        <div className={"name-input"}>
          <TextInput
            label="First Name"
            value={firstName}
            onChange={setFirstName}
            placeholder="Jane"
            autoFocus
            capFirstLetter
          />
        </div>
        <div className={"name-input"}>
          <TextInput
            label="Last Name"
            value={lastName}
            onChange={setLastName}
            placeholder="Smith"
            capFirstLetter
            onKeyPressEnter={() => {
              if (controls.canGoForward() && firstName && lastName) {
                controls.goForward();
              }
            }}
          />
        </div>
        <Button
          text="Continue"
          onClick={controls.goForward}
          disabled={!controls.canGoForward() || !firstName || !lastName}
        />
      </div>
      <SVGCircleLarge className={"bg-circle large-circle"} />
      <SVGCircleMedium className={"bg-circle medium-circle"} />
      <SVGCircleSmall className={"bg-circle small-circle"} />
    </Card>
  );
};

export default NameQuestionStep;
