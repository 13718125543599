import "./videoPlayer.scss";
import { useEffect, useState } from "react";

interface VideoPlayerProps {
  source: string | null;
  onTimeChange?: (time: number) => void;
  autoplay?: boolean;
  muted?: boolean;
  className?: string;
}

const VideoPlayer = ({
  source,
  autoplay = false,
  muted = false,
  className = "",
  onTimeChange = () => {},
}: VideoPlayerProps) => {
  const [currentTime, setCurrentTime] = useState(0);
  useEffect(() => {
    onTimeChange(currentTime);
  }, [currentTime]);
  const handleVideoPlayerMounted = (
    element: { currentTime: number } | null
  ) => {
    if (element !== null) {
      setInterval(() => {
        setCurrentTime(Math.floor(element.currentTime));
      }, 500);
    }
  };
  return (
    <div className="video-player">
      {source && (
        <video
          className={className}
          controls
          autoPlay={autoplay}
          muted={muted}
          ref={handleVideoPlayerMounted}
        >
          <source src={source} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
    </div>
  );
};

export default VideoPlayer;
