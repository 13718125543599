import { useCallback, useEffect, useState } from "react";
import { useLocalStorage } from "..";
import { AssessmentStep } from "../../types/assessment.types";

export type AnswerMap = {
  [assessmentId: number]: {
    [question: number]: number | undefined;
  };
};

/**
 * Uses the current navigation state to store any answer given
 * @param navigation The current navigation state
 * @returns All answer-related data
 */
const useAnswers = (currentStep?: AssessmentStep) => {
  const [answers, setAnswers] = useLocalStorage<AnswerMap>("answers", {});
  const [currentAnswer, setCurrentAnswer] = useState<number>();

  useEffect(() => {
    //When questions are updated or the navigation state changes, update the current question
    if (currentStep) {
      setCurrentAnswer(answers[currentStep.assessmentId]?.[currentStep.id]);
    }
  }, [currentStep, answers]);

  /**
   * Records an answer using the current navigation state
   * @param answer The given answer
   */
  const recordAnswer = useCallback(
    (answer: number) => {
      if (currentStep) {
        const { assessmentId, id } = currentStep;
        setAnswers({
          ...answers,
          [assessmentId]: {
            ...answers[assessmentId],
            [id]: answer,
          },
        });
      }
    },
    [currentStep]
  );

  return { answers, currentAnswer, recordAnswer };
};

export default useAnswers;
