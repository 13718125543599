import "./progressStepper.scss";
import { LogoHorizontal } from "../library/logos";
import { colors } from "../library";
import React from "react";
import { Link } from "react-router-dom";
import { Check } from "tabler-icons-react";

interface ProgressStepperProps {
  currentStep: number;
  className?: string;
  maxSteps: number;
  showSteps?: boolean;
  stepTitles: string[];
}

const ProgressStepper = ({
  currentStep,
  className = "",
  maxSteps,
  showSteps = false,
  stepTitles,
}: ProgressStepperProps) => {
  const steps = [];
  for (let i = 1; i < maxSteps + 1; i++) {
    steps.push(
      <div
        key={`stepper-${i}`}
        className={`stepper-item ${i < currentStep && "completed"} ${
          i === currentStep && "active"
        } ${i === 1 && "no-before"}`}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div className="step-counter">{i < currentStep ? <Check /> : i}</div>
          <p style={{ padding: " 0 0 .5em .5em", fontWeight: "bold" }}>
            {stepTitles[i - 1]}
          </p>
        </div>
      </div>
    );
  }
  return (
    <div className={`stepper-wrapper ${className}`}>
      <Link className="stepper-logo" to={"/"}>
        <LogoHorizontal color={colors.primary[4]} />
      </Link>
      <div className="step-indicators-wrapper" data-current={currentStep}>
        <div className="connector"></div>
        <div className="step-indicators">
          {stepTitles.map((stepTitle, i) => {
            return (
              <div
                className="step-indicator"
                key={`step-title-${i}`}
                data-index={i + 1}
                data-complete={i + 1 < currentStep}
                data-active={i + 1 === currentStep}
              >
                <div className="step-number">
                  {i + 1 < currentStep ? "✓" : i + 1}
                </div>
                <div className="step-title">{stepTitle}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ProgressStepper;
