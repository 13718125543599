import * as React from "react";
import { SVGProps } from "react";
import { CustomIconProps } from "./customIcon";

const CustomIconPrayerNoCircle = (props: CustomIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 402.3 402.3"
    width={props.size || undefined}
    height={props.size || undefined}
    {...props}
  >
    <defs>
      <style>
        {
          ".cls-1{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:40.3px}"
        }
      </style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-1"
          d="m201.15 382.15-17.99-86.18M219.14 106.33l-17.99-86.18M20.15 201.15l86.18-17.99M295.97 219.14l86.18-17.99M73.17 329.14l48.21-73.66M280.92 146.82l48.22-73.65M73.17 73.17l73.65 48.21M255.48 280.92l73.66 48.22"
        />
      </g>
    </g>
  </svg>
);

export default CustomIconPrayerNoCircle;
