import * as React from "react";
import { SVGProps } from "react";
import { CustomIconProps } from "./customIcon";

const CustomIconActs = (props: CustomIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1246.62 1132.4"
    width={props.size || undefined}
    height={props.size || undefined}
    {...props}
  >
    <g data-name="Layer 2">
      <g data-name="Layer 1">
        <path d="M1123.41 946a63.21 63.21 0 1 1 0 126.41H268.47V946h854.94m0-60H208.47v246.4h914.94a123.21 123.21 0 1 0 0-246.41ZM978.15 646.32v126.4H123.2a63.2 63.2 0 0 1 0-126.4h855m60-60h-915a123.2 123.2 0 0 0 0 246.4h915v-246.4ZM1186.62 359.67v126.41H331.67a63.21 63.21 0 0 1 0-126.41h854.95m60-60H331.67a123.21 123.21 0 0 0 0 246.41h914.95V299.67ZM1051.82 60a63.2 63.2 0 1 1 0 126.4H196.87V60h855m0-60h-915v246.4h915a123.2 123.2 0 1 0 0-246.4Z" />
      </g>
    </g>
  </svg>
);

export default CustomIconActs;
