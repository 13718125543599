import { useLocalStorage } from "..";
import {
  AssessmentStep,
  NavigationControls,
} from "../../types/assessment.types";
import useAnswers from "./useAnswers";
import useNavigationControls from "./useNavigationControls";
import useAssessmentSteps from "./useAssessmentSteps";
import useAssessmentResults, {
  AssessmentResults,
} from "./useAssessmentResults";
import { submitOnboardingAssessment } from "../../api/apiClient";

type WebAssessmentHookResult = {
  loaded: boolean;
  currentStep?: AssessmentStep;
  currentAnswer?: number;
  recordAnswer: (answer: number) => void;
  controls: NavigationControls;
  getProgress: () => [number, number];
  results: AssessmentResults;
  onAssessmentSubmit: (
    email: string,
    password: string
  ) => Promise<{ error?: string }>;
};

const useWebAssessment = (): WebAssessmentHookResult => {
  //Navigation needs to be here to be used across each helper hook
  const [assessmentStepIndex, setAssessmentStepIndex] = useLocalStorage<number>(
    "assessmentStepIndex",
    0
  );

  const { loaded, assessmentSteps, currentStep } =
    useAssessmentSteps(assessmentStepIndex);
  const { answers, currentAnswer, recordAnswer } = useAnswers(currentStep);
  const controls = useNavigationControls(
    assessmentStepIndex,
    setAssessmentStepIndex,
    assessmentSteps,
    currentAnswer
  );
  const results = useAssessmentResults(assessmentSteps, answers);

  /**
   * Gets the user's progress based on the current navigation state
   * @returns The current progress through the assessment (out of 100)
   */
  const getProgress = (): [number, number] => {
    if (!assessmentSteps) {
      return [0, 0];
    }
    const assessmentMap: { [key: number]: boolean } = {};
    for (let { assessmentId } of assessmentSteps) {
      assessmentMap[assessmentId] = true;
    }
    const assessments = Object.keys(assessmentMap).map(Number);
    const current = assessments.indexOf(currentStep?.assessmentId ?? 0) ?? 0;

    return [current + 1, assessments.length];
  };

  const onAssessmentSubmit = async (email: string, password: string) => {
    const result = await submitOnboardingAssessment(
      answers[-1][-1]!.toString(),
      email,
      password,
      answers,
      results
    );
    return result;
  };

  return {
    loaded,
    currentStep,
    currentAnswer,
    recordAnswer,
    controls,
    getProgress,
    results,
    onAssessmentSubmit,
  };
};

export default useWebAssessment;
