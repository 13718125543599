import * as React from "react";
import { SVGProps } from "react";
import { CustomIconProps } from "./customIcon";

const CustomIconIdentityCircle = (props: CustomIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 572.3 572.3"
    width={props.size || undefined}
    height={props.size || undefined}
    {...props}
  >
    <defs>
      <style>
        {
          ".cls-2{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:40px}"
        }
      </style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <circle
          cx={286.15}
          cy={286.15}
          r={266}
          style={{
            strokeWidth: "40.3px",
            fill: "none",
            stroke: "#000",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <circle cx={286.15} cy={286.15} r={102.75} />
        <path
          className="cls-2"
          d="m286.14 126.9-.32.43M285.82 445.56l.32-.02M445.46 286.22l.11-.41M127.34 285.81l-.52.41M398.79 173.56l-.5-.22M173.35 398.28l.13.59M398.79 398.87l-.5-.59M173.35 173.34l.13.22"
        />
      </g>
    </g>
  </svg>
);

export default CustomIconIdentityCircle;
