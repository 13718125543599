import React, { useState } from "react";
import { ProgressBarCircular } from "../../../components";
import {
  Button,
  Card,
  HalfCircleProgressBar,
} from "../../../components/library";
import CreateAccountModal from "../../../components/createAccountModal/createAccountModal";
import { AssessmentResults } from "../../../hooks/useWebAssessment/useAssessmentResults";
import { Link } from "react-router-dom";

interface ResultsStepProps {
  results: AssessmentResults;
  controls: any;
  onAssessmentSubmit: (
    email: string,
    password: string
  ) => Promise<{ error?: string }>;
}

const assessmentMetadata: { [key: number]: [string, string] } = {
  17: ["Identity", "#FCA12F"],
  18: ["Purpose", "#8373E3"],
  19: ["Action", "#4DCA7F"],
};

const ResultsStep = ({
  results,
  onAssessmentSubmit,
  controls,
}: ResultsStepProps) => {
  const [showEmailEntryModal, setShowEmailEntryModal] = useState(false);

  const descriptions: any = {
    Identity:
      "Your Identity score shows how well you understand your True Identity. Together, we'll take steps to understand who you really are.",
    Purpose:
      "Your Purpose score shows how well you understand your Created Purpose. Together, we'll discover what you were created to do.",
    Action:
      "Your Action score shows how well you use your Identity and Purpose. On our Journey, we'll make it a goal to see real change over time.",
  };

  return (
    <Card className={"results-card"}>
      <div className="assessment-step results">
        <div className="card-title-section">
          <h1>Your Results</h1>
          <p>
            We've compiled scores for your Identity, Purpose, and Action. Take a
            moment to look through your results. You'll also be able to see your
            results later on the My Nota page.
          </p>
        </div>

        <div className="result-wrapper">
          {results.map(({ id, result }) => {
            let [title, color] = assessmentMetadata[id];
            return (
              <div key={id}>
                <HalfCircleProgressBar value={result} title={title} />
                <p className={"small"}>{descriptions[title]}</p>
              </div>
            );
          })}
        </div>
        {/* <div className={"results-divider"} /> */}
        <Button
          text="Continue"
          onClick={controls.goForward}
          disabled={!controls.canGoForward()}
        />
        {/*<p style={{ marginBottom: "10px" }}>
          To access a copy of your results,{" "}
          <Link to={"/email"} target={"_blank"}>
            click here
          </Link>{" "}
          to receive them via email.
        </p>
        <p>
          Continue your journey of growth and realization on <b>Nota.</b>
        </p>
        <p>
          <Link to={"/download"} target={"_blank"}>
            Click here
          </Link>{" "}
          to download the app and get started.
        </p>*/}
      </div>
    </Card>
  );
};

export default ResultsStep;
