import * as React from "react";
import { SVGProps } from "react";
import { CustomIconProps } from "./customIcon";

const CustomIconLordsPrayer = (props: CustomIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 868.73 1158.14"
    width={props.size || undefined}
    height={props.size || undefined}
    {...props}
  >
    <defs>
      <style>
        {
          ".cls-1{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:50px}"
        }
      </style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-1"
          d="m25 936.8 157.28-99.59a55.9 55.9 0 0 0 26-46.64l12.34-280.31a142.31 142.31 0 0 1 3.8-31.24L366.61 51a37.06 37.06 0 0 1 43.73-25h0a37.06 37.06 0 0 1 28.19 42.19l-5.91 470.08"
        />
        <path
          className="cls-1"
          d="M115.29 1133.14C371.62 966.2 427.71 886.78 431.8 781.82c2.48-63.5 3.59-136.35.82-243.59h0M843.73 936.8l-157.28-99.59a55.9 55.9 0 0 1-26-46.64l-12.33-280.31a142.31 142.31 0 0 0-3.8-31.24L510.44 51a37.06 37.06 0 0 0-43.73-25h0a37 37 0 0 0-28.18 42.19l-2.42 470.08"
        />
        <path
          className="cls-1"
          d="M753.44 1133.14C497.11 966.2 441 886.78 436.93 781.82c-2.48-63.5-3.58-136.35-.82-243.59h0"
        />
        <path
          className="cls-1"
          d="M434.76 685.67V537.48a44.61 44.61 0 0 1 44.61-44.61h0A44.61 44.61 0 0 1 524 537.48v190.74M434.23 685.67V537.48a44.61 44.61 0 0 0-44.61-44.61h0A44.61 44.61 0 0 0 345 537.48v190.74"
        />
      </g>
    </g>
  </svg>
);

export default CustomIconLordsPrayer;
