import React from "react";
import "./spinner.scss";

const LoaderSpinner = () => {
  return (
    <div className="lds-spinner">
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

export default LoaderSpinner;
