import { AnswerMap } from "../../hooks/useWebAssessment/useAnswers";
import { AssessmentResults } from "../../hooks/useWebAssessment/useAssessmentResults";
import {
  AssessmentInteractionDTO,
  QuestionInteractionDTO,
  OnboardingAssessmentDTO,
} from "../../types/api.types";
import { TransformFunction } from "./transformFunction";

type AssessmentResultData = {
  email: string;
  name: string;
  password: string;
  answers: AnswerMap;
  results: AssessmentResults;
};
export const createOnboardingAssessmentDTO: TransformFunction<
  AssessmentResultData,
  OnboardingAssessmentDTO
> = ({ answers, results, name, ...info }) => {
  const [nameFirst, nameLast] = parseName(name);
  return {
    ...info,
    nameFirst,
    nameLast,
    questions: createQuestionInteractionDTOs(answers),
    assessments: createAssessmentInteractionDTOs(results),
  };
};

const parseName = (name: string): [string, string] => {
  const split = name.split(" ");
  if (split.length < 2) {
    return [name, ""];
  } else {
    return [split[0], split.slice(1).join(" ")];
  }
};

export const createQuestionInteractionDTOs: TransformFunction<
  AnswerMap,
  QuestionInteractionDTO[]
> = (answers) => {
  return Object.entries(answers).flatMap(([assessmentId, questions]) =>
    Object.entries(questions)
      .map(([questionId, answer]) => ({
        assessmentId: Number(assessmentId),
        questionId: Number(questionId),
        answer: Number(answer),
      }))
      .filter(({ questionId }) => questionId > 0)
  );
};

export const createAssessmentInteractionDTOs: TransformFunction<
  AssessmentResults,
  AssessmentInteractionDTO[]
> = (results) => {
  return results.map(({ id, result }) => ({
    assessmentId: id,
    result,
  }));
};
