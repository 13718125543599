import * as React from "react";
import { SVGProps } from "react";
import { MulticolorProps } from "./multicolor";

const MulticolorMailIcon = (props: MulticolorProps) => {
  const { colors = ["black", "grey"] } = props;
  return (
    <svg
      width={80}
      height={80}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 6.4h48a4 4 0 0 1 4 4v36a4 4 0 0 1-4 4H16a4 4 0 0 1-4-4v-36a4 4 0 0 1 4-4Zm6 12a2 2 0 0 0 0 4h24a2 2 0 1 0 0-4H22Zm0 8a2 2 0 0 0 0 4h12a2 2 0 1 0 0-4H22Z"
        fill={colors[0]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.172 24.694 40 48.402l32.828-23.708A2 2 0 0 1 76 26.314v40.088a8 8 0 0 1-8 8H12a8 8 0 0 1-8-8V26.314a2 2 0 0 1 3.172-1.62Z"
        fill={colors[1]}
      />
    </svg>
  );
};

export default MulticolorMailIcon;
