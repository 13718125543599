import {
  AssessmentStep,
  NavigationControls,
} from "../../types/assessment.types";

const useNavigationControls = (
  assessmentStepIndex: number,
  setAssessmentStepIndex: (index: number) => void,
  assessmentSteps: AssessmentStep[],
  currentAnswer?: number
): NavigationControls => {
  /**
   * Navigates a certain number of questions forwards or backwards
   * @param increment The number of questions to change
   */
  const navigate = (increment: number) => {
    let newIndex = assessmentStepIndex + increment;

    if (validateAssessmentIndex(newIndex)) {
      setAssessmentStepIndex(newIndex);
    }
  };

  /**
   * Ensures that a given navigation state is valid
   * @param navigation A navigation state
   * @returns Whether or not the navigation state is valid
   */
  const validateAssessmentIndex = (index: number) => {
    return !!assessmentSteps[index];
  };

  /**
   * @returns Whether the app can navigate backwards
   */
  const canGoBack = () => assessmentStepIndex > 0;
  /**
   * @returns Whether the app can navigate forwards
   */
  const canGoForward = () =>
    checkForAnswer() && assessmentStepIndex < assessmentSteps.length - 1;

  const checkForAnswer = () => {
    const step = assessmentSteps[assessmentStepIndex];
    if (step.type === "question") {
      return !!currentAnswer;
    }

    return true;
  };

  /**
   * Navigates backward
   */
  const goBack = () => navigate(-1);
  /**
   * Navigates forward
   */
  const goForward = () => navigate(1);

  return {
    canGoBack,
    canGoForward,
    goBack,
    goForward,
  };
};

export default useNavigationControls;
