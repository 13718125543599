const TermsOfUsePage = () => {
  return (
    <div className="legal-page">
      <h1>Terms of Use</h1>
      <p>These are our terms</p>
    </div>
  );
};

export default TermsOfUsePage;
