import * as React from "react";
import { SVGProps } from "react";
import { CustomIconProps } from "./customIcon";

const CustomIconPurposeCircle = (props: CustomIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 572.3 572.3"
    width={props.size || undefined}
    height={props.size || undefined}
    {...props}
  >
    <defs>
      <style>
        {
          ".cls-1{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:40.3px}"
        }
      </style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-1"
          d="M417.14 345.26a131 131 0 1 1-262 0c0-72.34 131-249.2 131-249.2s131 176.86 131 249.2Z"
        />
        <circle className="cls-1" cx={286.15} cy={286.15} r={266} />
        <path d="M321.15 345.65a35.5 35.5 0 0 1-71 0c0-19.6 35.5-85.5 35.5-85.5s35.5 65.9 35.5 85.5Z" />
      </g>
    </g>
  </svg>
);

export default CustomIconPurposeCircle;
