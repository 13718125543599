import React, { useEffect, useState } from "react";
import { Slider } from "../../../components";
import SliderAnswers from "../../../components/sliderAnswers/sliderAnswers";
import { QuestionResource } from "../../../types/resource.types";
import { Button, Card } from "../../../components/library";
interface SliderQuestionProps {
  resource: QuestionResource;
  value: number;
  onChange: (value: number) => void;
  controls: any;
}

const RangeQuestionStep = ({
  resource,
  value,
  onChange,
  controls,
}: SliderQuestionProps) => {
  const mapOptions = () => {
    return resource.data.options.map(({ text }) => text).reverse();
  };
  const [options, setOptions] = useState(mapOptions());

  useEffect(() => {
    setOptions(mapOptions());
  }, [resource]);

  return (
    <Card>
      <div className="assessment-step range">
        <div className="card-title-section">
          <h1>{resource.title}</h1>
          <p>{resource.data.text}</p>
        </div>

        <SliderAnswers options={options} value={value} onClick={onChange} />
        <div className="range-wrapper">
          <Slider
            value={value}
            onChange={(value: number) => {
              onChange(value);
            }}
          />
        </div>
        <Button
          text="Continue"
          onClick={controls.goForward}
          disabled={!controls.canGoForward()}
        />
      </div>
    </Card>
  );
};

export default RangeQuestionStep;
