import React, { PropsWithChildren, useState } from "react";
import {
  colors,
  Button,
  Spacer,
  TextInput,
  Dropdown,
} from "../../components/library";
import {
  MulticolorBriefcaseIcon,
  MulticolorListIcon,
  MulticolorMapIcon,
  MulticolorMountainIcon,
  MulticolorQuestionMarkIcon,
  CustomIconIAmCircle,
  CustomIconActs,
  CustomIconActionCircle,
  CustomIconActionNoCircle,
  CustomIconIAmNoCircle,
  CustomIconIdentityCircle,
  CustomIconIdentityNoCircle,
  CustomIconLordsPrayer,
  CustomIconPrayerWithCircle,
  CustomIconPrayerNoCircle,
  CustomIconPurposeCircle,
  CustomIconPurposeNoCircle,
} from "../../components/library/icons";
import MulticolorMailIcon from "../../components/library/icons/multicolor/mail";
import { Activity, Atom, BellPlus, Eye } from "tabler-icons-react";
import {
  LogoHorizontal,
  LogoOnlyThumbprint,
  LogoVertical,
} from "../../components/library/logos";
type SectionProps = {
  title: string;
  openByDefault?: boolean;
  isSubsection?: boolean;
};
const DemoPage = () => {
  const Section = (props: PropsWithChildren<SectionProps>) => {
    const {
      title,
      children,
      openByDefault = false,
      isSubsection = false,
    } = props;
    const [isOpen, setIsOpen] = useState(openByDefault);
    const sectionTitle = () => (
      <h1
        style={{ cursor: "pointer" }}
        onClick={() => setIsOpen(!isOpen)}
      >{`---- ${title} ----`}</h1>
    );
    const subSectionTitle = () => (
      <h1
        style={{ cursor: "pointer" }}
        className={"alt demoPageSubSection"}
        onClick={() => setIsOpen(!isOpen)}
      >{`---- ${title} ----`}</h1>
    );
    const SectionTitle = !isSubsection ? sectionTitle : subSectionTitle;
    return (
      <div>
        <SectionTitle />
        {isOpen && (
          <>
            {children}
            <SectionTitle />
          </>
        )}
      </div>
    );
  };
  const ColorTile = (props: { color: string }) => {
    return (
      <div
        style={{
          width: "100%",
          maxWidth: "300px",
          height: "20px",
          backgroundColor: props.color,
          margin: "10px 0",
          fontStyle: "italic",
        }}
      />
    );
  };
  return (
    <div>
      <Section title={"Colors"}>
        <Section title={"Primary"} isSubsection>
          <ColorTile color={colors.primary[1]} />
          <ColorTile color={colors.primary[2]} />
          <ColorTile color={colors.primary[3]} />
          <ColorTile color={colors.primary[4]} />
          <ColorTile color={colors.primary[5]} />
          <ColorTile color={colors.primary[6]} />
        </Section>
        <Section title={"Accent"} isSubsection>
          <ColorTile color={colors.accent[1]} />
          <ColorTile color={colors.accent[2]} />
          <ColorTile color={colors.accent[3]} />
          <ColorTile color={colors.accent[4]} />
          <ColorTile color={colors.accent[5]} />
          <ColorTile color={colors.accent[6]} />
        </Section>
        <Section title={"Error"} isSubsection>
          <ColorTile color={colors.error[1]} />
          <ColorTile color={colors.error[2]} />
          <ColorTile color={colors.error[3]} />
          <ColorTile color={colors.error[4]} />
          <ColorTile color={colors.error[5]} />
          <ColorTile color={colors.error[6]} />
        </Section>
        <Section title={"Success"} isSubsection>
          <ColorTile color={colors.success[1]} />
          <ColorTile color={colors.success[2]} />
          <ColorTile color={colors.success[3]} />
          <ColorTile color={colors.success[4]} />
          <ColorTile color={colors.success[5]} />
          <ColorTile color={colors.success[6]} />
        </Section>
        <Section title={"Warning"} isSubsection>
          <ColorTile color={colors.warning[1]} />
          <ColorTile color={colors.warning[2]} />
          <ColorTile color={colors.warning[3]} />
          <ColorTile color={colors.warning[4]} />
          <ColorTile color={colors.warning[5]} />
          <ColorTile color={colors.warning[6]} />
        </Section>
        <Section title={"Grey Scale"} isSubsection>
          <ColorTile color={colors.greyScale[1]} />
          <ColorTile color={colors.greyScale[2]} />
          <ColorTile color={colors.greyScale[3]} />
          <ColorTile color={colors.greyScale[4]} />
          <ColorTile color={colors.greyScale[5]} />
          <ColorTile color={colors.greyScale[6]} />
        </Section>
      </Section>
      <Section title={"Typography"}>
        <Section title={"Headings"} isSubsection>
          <h1>Heading 1</h1>
          <h1 className={"alt"}>Alternative Heading 1</h1>
          <h2>Heading 2</h2>
          <h3>Heading 3</h3>
          <h4>Heading 4</h4>
          <h5>Heading 5</h5>
          <h6>Heading 6</h6>
        </Section>
        <Section title={"Body"} isSubsection>
          <p>This Is A Regular Body</p>
          <p className={"medium"}>This Is A Medium Body</p>
          <p className={"small"}>This Is A Small Regular Body</p>
          <p className={"small medium"}>This Is A Small Medium Body</p>
        </Section>
      </Section>
      <Section title={"Buttons"}>
        <Section title={"Primary"} isSubsection>
          <Button text={"Primary Button"} />
          <Spacer size={4} />
          <Button disabled text={"Disabled Button"} />
        </Section>
        <Section title={"Secondary"} isSubsection>
          <Button type={"secondary"} text={"Secondary Button"} />
          <Spacer size={4} />
          <Button disabled text={"Disabled Button"} />
        </Section>
      </Section>
      <Section title={"Inputs"}>
        <Section title={"Text Input"} isSubsection>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <TextInput
              label="Input"
              placeholder={"Regular Input"}
              onChange={(val) => {
                console.log(val);
              }}
            />
            <TextInput
              label="Icon Input"
              placeholder={"Regular Input With Icon"}
              icon={<Eye size={20} />}
              iconOnClick={() => console.log("icon clicked")}
            />
            <Spacer size={4} />
            <TextInput
              label="disabled input"
              placeholder={"Disabled Input"}
              disabled={true}
            />
          </div>
        </Section>
        <Section title={"Dropdown"} isSubsection>
          <Dropdown
            options={[
              { label: "Option A", value: "Option A" },
              { label: "Option B", value: "Option B" },
              { label: "Option C", value: "Option C" },
              { label: "Option D", value: "Option D" },
            ]}
            onChange={(value) => {
              console.log(value);
            }}
          />
        </Section>
      </Section>
      <Section title={"Icons"}>
        <Section title={"Multi-Color"} isSubsection>
          <MulticolorMapIcon />
          <MulticolorListIcon />
          <MulticolorBriefcaseIcon />
          <MulticolorMountainIcon />
          <MulticolorQuestionMarkIcon />
          <MulticolorMailIcon />
        </Section>
        <Section title={"Tabler"} isSubsection>
          <Activity size={48} color="black" />
          <Atom size={48} color="black" />
          <BellPlus size={48} color="black" />
        </Section>
        <Section title={"Custom Icons"} isSubsection>
          <CustomIconIAmCircle size={60} />
          <CustomIconLordsPrayer size={60} />
          <CustomIconIAmNoCircle size={60} />
          <CustomIconIdentityNoCircle size={60} />
          <CustomIconIdentityCircle size={60} />
          <CustomIconActionNoCircle size={60} />
          <CustomIconActionCircle size={60} />
          <CustomIconActs size={60} />
          <CustomIconPrayerWithCircle size={60} />
          <CustomIconPrayerNoCircle size={60} />
          <CustomIconPurposeCircle size={60} />
          <CustomIconPurposeNoCircle size={60} />
        </Section>
      </Section>
      <Section title={"Logos"}>
        <Section title={"Vertical"} isSubsection>
          <LogoVertical scale={1} />
        </Section>
        <Section title={"Horizontal"} isSubsection>
          <LogoHorizontal scale={1} />
        </Section>
        <Section title={"Thumbprint"} isSubsection>
          <LogoOnlyThumbprint scale={1} />
        </Section>
      </Section>
    </div>
  );
};

export default DemoPage;
