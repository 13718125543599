import MediaStep from "./mediaStep";
import RangeQuestionStep from "./rangeQuestionStep";
import InterstitialStep from "./interstitialStep";
import { NavigationControls } from "../../../types/assessment.types";
import NameQuestionStep from "./nameQuestionStep";
import ResultsStep from "./resultsStep";
import { AssessmentResults } from "../../../hooks/useWebAssessment/useAssessmentResults";
import {
  InterstitialResource,
  MediaResource,
  QuestionResource,
  Resource,
} from "../../../types/resource.types";
import AccountStep from "./accountStep";
import { DownloadPage } from "../../index";
import CreateAccountStep from "./createAccountStep";

type AssessmentStepResourceProps = {
  currentStep: Resource;
  currentAnswer?: number;
  recordAnswer: (value: number) => void;
  controls: NavigationControls;
  results: AssessmentResults;
  onAssessmentSubmit: (
    email: string,
    password: string
  ) => Promise<{ error?: string }>;
};
const AssessmentStepResource = ({
  currentStep,
  currentAnswer,
  recordAnswer,
  controls,
  results,
  onAssessmentSubmit,
}: AssessmentStepResourceProps) => {
  const { type } = currentStep;
  switch (type) {
    case "name":
      return (
        <NameQuestionStep
          controls={controls}
          value={String(currentAnswer ?? "")}
          //@ts-ignore
          onChange={recordAnswer}
        />
      );
    case "question":
      return (
        <RangeQuestionStep
          controls={controls}
          resource={currentStep as QuestionResource}
          onChange={(value) => recordAnswer(Number(value))}
          value={(currentAnswer ?? 0) as number}
        />
      );
    case "interstitial":
      return (
        <InterstitialStep
          resource={currentStep as InterstitialResource}
          goForward={controls.goForward}
        />
      );
    case "media":
      return (
        <MediaStep
          controls={controls}
          resource={currentStep as MediaResource}
        />
      );
    case "results":
      return (
        <ResultsStep
          results={results}
          controls={controls}
          onAssessmentSubmit={onAssessmentSubmit}
        />
      );
    case "account":
      return <CreateAccountStep controls={controls} onChange={() => {}} />;
    case "app":
      return <DownloadPage />;
    default:
      return null;
  }
};

export default AssessmentStepResource;
