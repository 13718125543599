import { useEffect, useState } from "react";
import { AssessmentStep } from "../../types/assessment.types";
import { AnswerMap } from "./useAnswers";

export type AssessmentResults = {
  id: number;
  name: string;
  result: number;
}[];

type AssessmentTotals = {
  [assessmentId: number]: {
    total: number;
    count: number;
  };
};

const useAssessmentResults = (
  assessmentSteps: AssessmentStep[],
  answers: AnswerMap
) => {
  const [results, setResults] = useState<AssessmentResults>([]);

  useEffect(() => {
    if (assessmentSteps?.length && Object.keys(answers).length > 0) {
      calculateResults();
    }
  }, [assessmentSteps, answers]);

  const calculateResults = () => {
    const assessmentTotals: AssessmentTotals = {};
    for (let step of assessmentSteps) {
      if (step.assessmentId > 0 && step.type === "question") {
        if (!assessmentTotals[step.assessmentId]) {
          assessmentTotals[step.assessmentId] = {
            total: 0,
            count: 0,
          };
        }
        assessmentTotals[step.assessmentId].total += Number(
          answers?.[step.assessmentId]?.[step.id] ?? 0
        );
        assessmentTotals[step.assessmentId].count++;
      }
    }

    const newResults: AssessmentResults = [];
    for (let [assessmentId, { total, count }] of Object.entries(
      assessmentTotals
    )) {
      newResults.push({
        id: Number(assessmentId),
        result: Math.floor(total / count),
        name: "Test",
      });
    }
    setResults(newResults);
  };

  return results;
};

export default useAssessmentResults;
