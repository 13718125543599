import { ProgressStepper } from "../../components";
import { Button } from "../../components/library";
import AssessmentStepResource from "./assessmentStepResource";
import "./assessmentStepPage.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import useWebAssessment from "../../hooks/useWebAssessment";

interface AssessmentStepPageProps {}

const AssessmentStepPage = ({}: AssessmentStepPageProps) => {
  const { loaded, currentStep, controls, getProgress, ...assessmentProps } =
    useWebAssessment();
  const navigate = useNavigate();
  const stepTitles = [
    "Info",
    "Identity",
    "Purpose",
    "Action",
    "Results",
    "Account",
    "App",
  ];
  let currentStepNumber = 0;
  const onBackClick = () => {
    if (controls.canGoBack()) {
      controls.goBack();
    } else {
      navigate("../");
    }
  };

  const type = currentStep?.type;
  const currentStepSection = currentStep?.slug.split("-")[0].toUpperCase();
  const newCurrentStepNumber = stepTitles.findIndex((title: string) => {
    return title.toUpperCase() === currentStepSection;
  });
  if (newCurrentStepNumber !== -1) {
    currentStepNumber = newCurrentStepNumber;
  }
  const progress = getProgress();

  if (!loaded || !currentStep) {
    return null;
  }

  return (
    <div className="page assessment-step-page assessment-page">
      <div className="background"></div>
      {type !== "interstitial" && (
        <ProgressStepper
          stepTitles={stepTitles}
          currentStep={currentStepNumber + 1}
          maxSteps={stepTitles.length}
        />
      )}
      <AssessmentStepResource
        currentStep={currentStep}
        controls={controls}
        {...assessmentProps}
      />
    </div>
  );
};

export default AssessmentStepPage;
