import "./footer.scss";
import logo from "../../../../logoBlack.png";
import AppStoreLink from "../../../appStoreLink/appStoreLink";
import { LogoHorizontal } from "../../../library/logos";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer--address">
        <div
          style={{
            height: "50px",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <LogoHorizontal />
        </div>

        <p className="address-line">2022 Nota. All rights reserved</p>
      </div>
      <div className="footer--nav-links">
        <div className="footer--nav-column">
          <h5>Explore</h5>
          <Link to="/how-it-works">How It Works</Link>
          <Link to="/about">About</Link>
        </div>
        <div className="footer--nav-column">
          <h5>Legal</h5>
          <a
            target={"_blank"}
            href={
              "https://app.termly.io/document/eula/ff3e67f1-789f-4232-adeb-66bd4c3f5a58"
            }
          >
            EULA
          </a>
          <a
            target={"_blank"}
            href={
              "https://app.termly.io/document/privacy-policy/a704575a-ea8c-42f2-8d85-4744ba4cac82"
            }
          >
            Privacy Policy
          </a>
        </div>
      </div>

      {/*<div className="footer--nav-column">
        <h5>Contact</h5>
        <p>info@nota.com</p>
        <p>(217) 555-0113</p>
        <p>
          4517 Washington Ave.
          <br /> Manchester, Kentucky 39495
        </p>
      </div>*/}
    </div>
  );
};

export default Footer;
