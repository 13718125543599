import { useEffect, useState } from "react";
import { getOnboardingAssessments } from "../../api/apiClient";
import { AssessmentStep } from "../../types/assessment.types";
import { Resource } from "../../types/resource.types";

/**
 * Gets questions from the API, stores them, and uses the current navigation state
 * to keep track of the current question
 * @param navigation The current navigation state
 * @returns All question-related data
 */
const useAssessmentSteps = (assessmentStepIndex: number) => {
  const [loaded, setLoaded] = useState(false);
  const [assessmentSteps, setAssessmentSteps] = useState<AssessmentStep[]>([]);
  const [currentStep, setCurrentStep] = useState<AssessmentStep>();

  useEffect(() => {
    //When questions are updated or the navigation state changes, update the current question
    if (assessmentSteps.length) {
      setCurrentStep(assessmentSteps[assessmentStepIndex]);
    }
  }, [assessmentStepIndex, assessmentSteps]);

  const loadAssessmentSteps = async () => {
    const nameStep: AssessmentStep = {
      id: -1,
      assessmentId: -1,
      slug: "name",
      title: "Nice to meet you.",
      description: "placeholder name step",
      type: "name",
      data: {},
    };

    const resultStep: AssessmentStep = {
      id: -1,
      assessmentId: -1,
      slug: "results",
      title: "Results",
      description: "placeholder result step",
      type: "results",
      data: {},
    };
    const assessments = await getOnboardingAssessments();
    let result: AssessmentStep[] = [];
    let maxStepId = 0;
    let maxAssesmentId = 0;

    const addStepAndIncreaseMaxIds = (step: any, assessmentId?: number) => {
      result.push({
        ...step,
        assessmentId: assessmentId ? assessmentId : maxAssesmentId,
      });
      maxAssesmentId = assessmentId ? assessmentId + 1 : maxAssesmentId + 1;
      maxStepId = step.id >= maxStepId ? step.id + 1 : maxStepId;
    };

    for (let assessment of assessments) {
      for (let step of assessment.resources) {
        addStepAndIncreaseMaxIds(step, assessment.id);
        /*result.push({
          ...step,
          assessmentId: assessment.id,
        });
        maxAssesmentId = assessment.id + 1;
        maxStepId = step.id + 1;*/
      }
    }

    const resultsStep: AssessmentStep = {
      id: maxStepId,
      assessmentId: maxAssesmentId,
      slug: "results",
      title: "Results",
      description: "Results",
      type: "results",
      data: {},
    };
    addStepAndIncreaseMaxIds(resultsStep);

    const createAccountStep: AssessmentStep = {
      id: maxStepId,
      assessmentId: maxAssesmentId,
      slug: "account",
      title: "Account",
      description: "Create Your Account",
      type: "account",
      data: {},
    };
    addStepAndIncreaseMaxIds(createAccountStep);

    const downloadAppStep: AssessmentStep = {
      id: maxStepId,
      assessmentId: maxAssesmentId,
      slug: "app",
      title: "App",
      description: "Download The App",
      type: "app",
      data: {},
    };
    addStepAndIncreaseMaxIds(downloadAppStep);
    setAssessmentSteps([nameStep, ...result, resultStep]);
    setLoaded(true);
  };

  useEffect(() => {
    loadAssessmentSteps();
  }, []);

  return {
    loaded,
    assessmentSteps,
    currentStep,
  };
};

export default useAssessmentSteps;
