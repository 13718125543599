import * as React from "react";
import { SVGProps } from "react";
import { CustomIconProps } from "./customIcon";

const CustomIconActionCircle = (props: CustomIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 559.3 559.3"
    width={props.size || undefined}
    height={props.size || undefined}
    {...props}
  >
    <defs>
      <style>
        {
          ".cls-1{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:40.3px}"
        }
      </style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <circle className="cls-1" cx={368.84} cy={279.65} r={106.31} />
        <path
          className="cls-1"
          d="M206 279.65h-23.9M209.33 209.32H101.15M209.33 349.98H101.15"
        />
        <circle className="cls-1" cx={279.65} cy={279.65} r={259.5} />
      </g>
    </g>
  </svg>
);

export default CustomIconActionCircle;
