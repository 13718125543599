import * as React from "react";
import { SVGProps } from "react";
import { CustomIconProps } from "./customIcon";

const CustomIconPurposeNoCircle = (props: CustomIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 302.27 420.49"
    width={props.size || undefined}
    height={props.size || undefined}
    {...props}
  >
    <g data-name="Layer 2">
      <g data-name="Layer 1">
        <path
          d="M282.12 269.36a131 131 0 0 1-262 0c0-72.34 131-249.21 131-249.21s131 176.85 131 249.21Z"
          style={{
            fill: "none",
            stroke: "#000",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "40.3px",
          }}
        />
        <path d="M186.14 269.75a35.5 35.5 0 0 1-71 0c0-19.61 35.5-85.5 35.5-85.5s35.5 65.89 35.5 85.5Z" />
      </g>
    </g>
  </svg>
);

export default CustomIconPurposeNoCircle;
