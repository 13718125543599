import * as React from "react";
import { SVGProps } from "react";
import { MulticolorProps } from "./multicolor";

const MulticolorMapIcon = (props: MulticolorProps) => {
  const { colors = ["black"] } = props;
  return (
    <svg
      width={80}
      height={80}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m71.675 65.925-4.125-17.5a6.85 6.85 0 0 0-6.7-5.3H56.2c-2.32 4.123-5.05 8.001-8.15 11.575A10.75 10.75 0 0 1 40 58.375a10.55 10.55 0 0 1-8-3.65 68.651 68.651 0 0 1-8.2-11.6h-4.65a6.825 6.825 0 0 0-6.65 5.3l-4.15 17.5a6.875 6.875 0 0 0 6.65 8.45h50a6.823 6.823 0 0 0 6.675-8.45ZM55 66.875H25a1.875 1.875 0 1 1 0-3.75h30a1.875 1.875 0 0 1 0 3.75ZM42.675 5.825a18.125 18.125 0 0 0-20.8 17.925c0 11.85 8.1 22.95 12.95 28.5a6.826 6.826 0 0 0 10.375 0C49.8 46.95 57.5 36.4 58.1 25A18.2 18.2 0 0 0 42.675 5.825ZM40 28.75a5 5 0 1 1 0-10 5 5 0 0 1 0 10Z"
        fill={colors[0]}
      />
    </svg>
  );
};

export default MulticolorMapIcon;
