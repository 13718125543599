import * as React from "react";
import { SVGProps } from "react";
import { CustomIconProps } from "./customIcon";

const CustomIconIdentityNoCircle = (props: CustomIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 358.75 358.66"
    width={props.size || undefined}
    height={props.size || undefined}
    {...props}
  >
    <defs>
      <style>
        {
          ".cls-1{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:40px}"
        }
      </style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <circle cx={179.33} cy={179.25} r={102.75} />
        <path
          className="cls-1"
          d="m179.32 20-.32.43M179 338.66l.32-.02M338.64 179.32l.11-.41M20.52 178.91l-.52.41M291.97 66.66l-.5-.22M66.53 291.37l.13.6M291.97 291.97l-.5-.6M66.53 66.44l.13.22"
        />
      </g>
    </g>
  </svg>
);

export default CustomIconIdentityNoCircle;
