import * as React from "react";
import { SVGProps } from "react";

const SVGCircleLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={120}
    height={120}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={60} cy={60} r={60} fill="#277B9D" />
  </svg>
);

export default SVGCircleLarge;
